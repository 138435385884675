<template>
  <el-menu
    class="h-v"
    
    active-text-color="#168ED1"
    :collapse="isCollapse"
    :collapse-transition="false"
    :unique-opened="false"
    router
    style="border-right: none"
    :default-active="activePath"
  >
    <template v-for="item in menuList">
      <!-- 一级菜单 -->
      <router-link :to="item.url" :key="item.id+'a'" v-if="!item.hasOwnProperty('children')">
        <el-menu-item v-if="item.id==0" :index="item.url + ''">
          
          <div class="box_flex">
            <img width="30" height="30" style="margin-right:1px;" src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/%E4%BC%81%E4%B8%9A%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_16768747756564.png" alt="">
           <div class="name_flex">
            <span class="name_zhican">{{ item.authName }}</span>
            <!-- <span class="name_botton">为企业数智化转型升级服务</span> -->
           </div>
          </div>
         
        </el-menu-item>
        <el-menu-item v-else :index="item.url + ''">
          <i :class="item.icon" class="iconfont" />
          <span style="font-weight:550 ;font-size: 17px;">{{ item.authName }}</span>
         
        </el-menu-item>

      </router-link>
      <el-submenu v-else :key="item.id + 1" :index="item.url + ''">
        <!-- 一级菜单模板区域 -->
        <template slot="title">
          <!-- 图标 -->
          <i :class="item.icon" class="iconfont" />
          <!-- 文本 -->
          <span style="font-weight:550 ;font-size: 17px;">{{ item.authName }}</span>
        </template>
        <!-- 二级菜单 -->
        <el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.url">
          <template slot="title">
            <!-- 图标 -->
            <!--  <i :class="subItem.meta.icon" />-->
            <!-- 文本 -->
            <span>{{ subItem.authName }}</span>
          </template>
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      activePath: '', // 当前路由
      menuList: [
        {
          id: 0,
          authName: '智参运营管理后台',
          url: '',
          icon: ''
        },
        {
          id: 1,
          authName: '工作台',
          url: '/cockpit/index',
          icon: 'icon-jr-icon-shuju'
        },
        {
          id: 5,
          authName: '城市合伙人',
          url: '/CityPartner/index',
          icon: 'icon-jr-icon-zhanshi'
        },
        
        {
          id: 2,
          authName: '运营管理',
          url: '/operate',
          icon: 'icon-jr-icon-user-1',
          children: [
            {
              id: 21,
              authName: '用户管理',
              url: '/user/list'
            },
            {
              id: 22,
              authName: '客户管理',
              url: '/operate/custom'
            },
            {
              id: 23,
              authName: '订单管理',
              url: '/operate/order'
            },
            
            
          ]
        },
        {
          id: 3,
          authName: '服务管理',
          url: '/service',
          icon: 'icon-jr-icon-one',
          children: [
            {
              id: 31,
              authName: '诊断服务',
              url: '/service/diagnosis'
            },
            {
              id: 32,
              authName: '顾问服务',
              url: '/service/consultant'
            },
            {
              id: 33,
              authName: '信息服务',
              url: '/service/content'
            },
            {
              id: 34,
              authName: '专家咨询',
              url: '/service/expert'
            },
           {
              id: 35,
              authName: '活动服务',
              url: '/service/activity'
            },
            {
              id: 36,
              authName: '报告服务',
              url: '/service/report'
            },
                   
          ]
        },
        {
          id: 4,
          authName: '系统管理',
          url: '/system',
          icon: 'icon-jr-icon-seven',
          children: [
            {
              id: 31,
              authName: '系统用户',
              url: '/system/user'
            },
            {
              id: 32,
              authName: '权限管理',
              url: '/system/authority'
            },
            {
              id: 33,
              authName: '日志管理',
              url: '/system/log'
            },
           
                   
          ]
        },
      ]
    }
  },
  created(){
    let menuTree=JSON.parse(sessionStorage.getItem('menuTree'))
    this.menuList=menuTree
   
    this.menuList.forEach(el=>{
      if(el.children.length==0){
       delete el.children
      }
    })
  },

}
</script>

<style lang="less" scoped>

.iconfont {
  color: #212121;
  margin-right: 12px;
}
.box_flex{
  position: relative;
  top: 12px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  .name_flex{
    margin-left: 5px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  .name_zhican{
    font-family: 'YouSheBiaoTiYuan';
font-weight: 900;
font-size: 16px;
color: #333333;
}
.name_botton{
  color: #686868;
  font-size: 10px;
}
}
}



</style>
