import axios from 'axios'
import {
  message
} from './tool'
let type=''
axios.post(`${process.env.VUE_APP_BASE_APIZ}/companyGinseng/listPartnerByUrl`,

{url:window.location.origin})
.then((res)=>{
  type=res.data.data
  sessionStorage.setItem('typeMode', JSON.stringify(res.data.data))

})
.catch((error)=>{
  message.error('系统正忙！')

})
// let type=JSON.parse(sessionStorage.getItem('typeMode')) //判断是否是直营还是加盟使用不同的url
let baseURL

if(type.directLeague=='1'){
   baseURL=process.env.VUE_APP_BASE_APIZ
}else{
   baseURL=process.env.VUE_APP_BASE_APIJ
}
const request = axios.create({
  baseURL:baseURL,
  timeout: 60000
})
request.interceptors.request.use(
  (success) => {
    if (sessionStorage.getItem('token')) {
      success.headers.token = sessionStorage.getItem('token')
    } else {
      success.headers.token = ''
    }
    return success
  },
  (err) => {
    return err
  }
)

request.interceptors.response.use(
  (success) => {
    return success
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          message.error('错误请求')
          break
        case 401:
          message.error('未授权，请重新登录')
          break
        case 403:
          message.error('拒绝访问')
          break
        case 404:
          message.error('请求错误，未找到该资源')
          break
        case 408:
          message.error('请求超时')
          break
        case 500:
          message.error('服务器端出错')
          break
        case 501:
          message.error('网络未实现')
          break
        case 502:
          message.error('网络错误')
          break
        case 503:
          message.error('服务不可用')
          break
        case 504:
          message.error('网络超时')
          break
        case 505:
          message.error('http版本不支持该请求')
          break
        default:
          message.error(`连接错误${err.response.status}`)
      }
    } else {
      message.error('连接到服务器失败')
    }
    return err
  }
)

export default request
