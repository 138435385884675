import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/theme/index.css'
import 'normalize.css'
import './assets/css/bts.less'
import '../static/font/iconfont.css'
import { hasPermission } from '@/utils/permission.js'
//单个图片上传
import ImgBigUpload from '@/components/ImgBigUpload'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('ImgBigUpload', ImgBigUpload)
Vue.prototype.$hasPerms = hasPermission

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker);

import echarts from 'echarts'
Vue.prototype.$echarts = echarts



new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
