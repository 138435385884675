<template>
  <div class="breadcrumb">
    <!-- <el-button type="text" icon="icon-jr-icon-back" /> -->
    <el-breadcrumb separator="/">
      <template v-for="(item, i) in levelList">
        <el-breadcrumb-item
          v-if="item.meta.title"
          :key="item.path"
          class="breadcrumb-item"
          :to="getPath(item.path)"
        >
          {{
            $route.params.paramsName && i === 2
              ? '' + $route.params.paramsName + ' ' + item.meta.title
              : item.meta.title
          }}
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  <div class="notice_flex">
    <div class="notice_bell" @click="onclick">
      <img width="16" height="16" src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Vector-cz.png" alt="">
       <span style="margin-left: 5px;">使用说明</span>
      </div>

    <div v-if="token" style="padding-right: 30px; float: right" @click="logout">
      <span>退出登录</span>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',

  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      levelList: [],
      token: sessionStorage.getItem('token'),
      parteninfo:JSON.parse(sessionStorage.getItem('typeMode')),
    }
  },

  computed: {
    getPath() {
      return function (path) {
        // if (path === '/businessData') return false

        let pathlocal = path

        if (pathlocal.indexOf(':') !== -1 && this.$route.params) {
          const paramsIndex = pathlocal.indexOf(':')

          const paramsStr = pathlocal.substring(paramsIndex).substring(1)

          pathlocal = pathlocal.substring(0, paramsIndex)

          pathlocal = pathlocal + this.$route.params[paramsStr]

          if (path !== pathlocal) {
            return pathlocal
          } else {
            return path
          }
        } else {
          return path
        }
      }
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
     //操作手册跳转
     onclick() {
      window.open(this.parteninfo.manualLink)
    },
    // 切换侧边栏展开状态
    toggleCollapse() {
      this.$emit('update:isCollapse', !this.isCollapse)
    },
    // 动态面包屑
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name)

      this.levelList = matched
    },
    logout() {
      this.$confirm('您确定退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('roleId')
          sessionStorage.removeItem('mobile')
          sessionStorage.removeItem('menuTree')
          location.href = '/login'
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .notice_flex{
    display: flex;
    align-items: center;
  }
  .notice_bell {
     margin-right: 30px;
     display: flex;
     align-items: center;
     cursor: pointer;
      :hover {
        color: #448aff;
      }

    }
  .el-breadcrumb {
    font-size: 16px;
    color: #252631;
  }
}
</style>
